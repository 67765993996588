export class LiveRequest {
    patientId: number;
    patientName: string;
    patientGender: string;
    patientAge: number;
    callbackRequestTime: string;
    groupName: string;
    alertStatus: string;
    activeStatusType: string;
    chatId: number;
    alertId: number

    constructor(
        patientId: number,
        patientName: string,
        patientGender: string,
        patientAge: number,
        callbackRequestTime: string,
        groupName: string,
        alertStatus: string,
        activeStatusType: string,
        chatId: number,
        alertId: number

    ) {
        this.patientId=patientId || null;
        this.patientName=patientName || null;
        this.patientGender=patientGender || null;
        this.patientAge=patientAge || null;
        this.callbackRequestTime=callbackRequestTime || null;
        this.groupName=groupName || null;
        this.alertStatus=alertStatus || null;
        this.activeStatusType=activeStatusType || null;
        this.chatId=chatId || null;
        this.alertId=alertId || null
    }
}


export class LiveRequestMaker {
    static create = () => new LiveRequest(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    );

    static clone = (obj: LiveRequest) => new LiveRequest(
        obj.patientId,
        obj.patientName,
        obj.patientGender,
        obj.patientAge,
        obj.callbackRequestTime,
        obj.groupName,
        obj.alertStatus,
        obj.activeStatusType,
        obj.chatId,
        obj.alertId
    )

    static castInto = (obj: any) => new LiveRequest(
        obj.patientId,
        obj.patientName,
        obj.patientGender.toLowerCase(),
        obj.patientAge,
        obj.callbackRequestTime,
        obj.groupName,
        obj.alertStatus,
        obj.activeStatusType,
        obj.chatId,
        obj.alertId
    )
}
