import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrsService {

  constructor(
    private http: HttpClient
  ) {
  }

  fetchCRSGroupMemberList = (pageNoToFetch: any, groupId?, search?, selectedMemberId?) => {
    const body = {
      "groupID": groupId || 6,
      "keyword": search || "",
      "statusList": selectedMemberId ? selectedMemberId : [],
      "pagination": {
        "pageNo": pageNoToFetch
      }


    }
    return this.http.post('/admin/getCrsList',
      body
    );
  }

  getCRSGroupDetail = () => {

    const body = {

    };

    return this.http.post('/crs/getGroupDetail', body);
  }

  getPatientBasedonCRS = (pageNoToFetch: any, crsGroup?, crsFilter?, search?) => {
    const body = {
      groupID: crsGroup ? crsGroup.id : "",
      crsFilter: crsFilter ? [crsFilter] : [],
      keyword: search ? search : "",
      pagination: {
        pageNo: pageNoToFetch
      }
    };
    return this.http.post('/crs/fetchPatientsWithCrsOfGroup', body);
  }

  getCRSGroupDetails = () => {
    return this.http.post('/crs/getGroupDetail', {});
  }

}
